<style lang="scss" scoped>
.table-header {
  border: 1px solid #ebeef5;
  border-bottom: none;
  height: 50px;
}
.refresh {
  background-color: #f5f5f5;
  width: 50px;
  &:hover .icon_refresh {
    color: rgba(0, 0, 0, 0.6);
  }
}
.icon_refresh {
  color: #999;
  line-height: 50px;
}
.operation {
  white-space: nowrap;
}
</style>
<template>
  <div class="account height_100 flex_tb">
    <!-- 條件刪選 -->
    <collapse @seach="state.seach" />
    <div class="width_100 table-header sizing flex_lr_m">
      <div>
        <i class="el-icon-s-grid pad20" style="color: #999"></i>
        <el-button type="primary" size="mini" @click="export_cli"
          >導出表格</el-button
        >
      </div>
      <div class="refresh sizing height_100 flex_c_m" @click="refresh">
        <i class="el-icon-refresh cursor fonts-22 icon_refresh"></i>
      </div>
    </div>
    <!-- 表格 -->
    <el-table
      :data="state.tableData"
      height="100%"
      border
      style="width: 100%"
      class="flex1"
      id="exportTab"
      @selection-change="selection_change"
    >
      <el-table-column prop="admin_id" label="ID" min-width="80">
      </el-table-column>
      <el-table-column prop="admin_name" label="管理員" min-width="80">
      </el-table-column>
      <el-table-column prop="type" label="類型" min-width="80">
        <template #default="scope">
          {{ scope.row.type == 1 ? "庫存" : "積分" }}
        </template>
      </el-table-column>
      <el-table-column prop="details" label="詳情" min-width="80">
      </el-table-column>
      <el-table-column prop="created_at" label="創建時間" min-width="80">
      </el-table-column>
      <el-table-column prop="updated_at" label="修改時間" min-width="80">
      </el-table-column>
    </el-table>
    <!-- 分頁 -->
    <div class="flex_m_r pain pad20">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="state.user.page"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="Number(state.count)"
      >
      </el-pagination>
    </div>
    <!-- 彈窗 -->
    <pop-up
      :dialogVisible="state.dialogVisible"
      :userId="'' + state.userId"
      @toggleDialogVisible="toggleDialogVisible"
      @reloadData="reloadData"
    />
    <!-- 提示框 -->
    <el-dialog title="提示" v-model="state.dialog" width="400px">
      <span>確定刪除嗎？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="state.dialog = false">取 消</el-button>
          <el-button type="primary" @click="dels_confirm">確 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, getCurrentInstance } from "vue";
import Collapse from "./components/collapse";
import PopUp from "./components/pop-up";
import FileSaver from "file-saver";
import XLSX from "xlsx";
export default {
  components: {
    Collapse,
    PopUp,
  },
  setup() {
    const req_table_data = () => {
      $http.getActivity(state.user).then((res) => {
        console.log(res);
        if (res.status !== 200) return;
        state.tableData = res.data.data;
        state.count = res.data.count;
      });
    };

    const state = reactive({
      tableData: [], // 表格数据
      user: {
        // 请求参数
        limit: 10,
        page: 1,
        type: "",
        admin_name: "",
        time: "",
      },
      count: "", // 總條數
      seach(data) {
        // 查询里面的数据
        console.log(data);
        state.user.admin_name = data.seatch_name;
        state.user.type = data.sort;
        state.user.time = data.time;
        req_table_data();
      },
      dialogVisible: false, // 弹窗开关
      dialog: false, // 提示框开关
      delAccountId: [], // 批量刪除的id
      delUserId: [], // 单个删除的id
      userId: "", // 用户id
    });

    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $http, $message } = proxy;

    // init
    req_table_data();

    // 分頁組件改變页数
    const handleCurrentChange = (val) => {
      state.user.page = val;
      req_table_data();
    };

    // 分頁組件改變每頁顯示条数
    const handleSizeChange = (val) => {
      state.user.limit = val;
      req_table_data();
    };

    // 刷新
    const refresh = () => {
      req_table_data();
    };

    // 弹出层的显示开关
    const toggleDialogVisible = () => {
      state.dialogVisible = false;
    };

    // 弹出层点击确定触发
    const reloadData = (data) => {
      console.log(data);
      req_table_data();
    };

    // 选中的id数组
    const selection_change = (selection) => {
      state.delAccountId = selection.map((item) => {
        item = item.id;
        return item;
      });
    };

    // 选中的id数组
    const del_accout = () => {
      state.delUserId = [];
      state.dialog = true;
    };

    // 确定弹出层点击确定
    const dels_confirm = async () => {
      // const res = await $http.delUser({
      //   id: state.delUserId.length === 0 ? state.delAccountId : state.delUserId,
      // });
      // if (res.status !== 200) return;
      // req_table_data();
      // $message.success("刪除成功");
      // state.dialog = false;
    };

    // 刪除用戶
    const deleteInfo = (data) => {
      console.log(data);
      state.delUserId = [data.id];
      state.dialog = true;
    };

    // 查看数据列表
    const lookInfo = (data) => {
      console.log(data);
      state.dialogVisible = true;
    };

    const export_cli = () => {
      var xlsxParam = { raw: true }; // 导出的内容只做解析，不进行格式转换
      var wb = XLSX.utils.table_to_book(
        document.querySelector("#exportTab"),
        xlsxParam
      );

      /* get binary string as output */
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          "活動歷程.xlsx"
        );
        $message.success("導出成功");
      } catch (e) {
        if (typeof console !== "undefined") {
          console.log(e, wbout);
        }
      }
      return wbout;
    };

    return {
      state,
      handleCurrentChange,
      handleSizeChange,
      refresh,
      toggleDialogVisible,
      reloadData,
      selection_change,
      del_accout,
      dels_confirm,
      deleteInfo,
      lookInfo,
      export_cli,
    };
  },
};
</script>
